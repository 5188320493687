import api from "@/axios";

export default class PedTipoService {

    getPedTipos() {
        return api.get('api/sspedtipoindex' ).then((res) => res.data);
    }

    getPedTipo(id) {
        const params = { id }
        return api.get('api/sspedtipoget', {params}).then((res) => res.data);
    }

    savePedTipo(pedtipo) {
        return api.post('api/sspedtiposave', {pedtipo} ).then((res) => res.data);
    }

    deletePedTipo(id) {
        const params = { id }
        return api.get('api/sspedtipodel', { params }).then((res) => res.data);
    }

}
